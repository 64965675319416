/* :root {
  color-scheme: dark;
  background: radial-gradient(circle at 10% 20%, rgb(0, 0, 0) 0%, rgb(64, 64, 64) 90.2%);
  text-emphasis-color: white;
} */

.profiles-container {
  padding: 30px;
  max-width: 300px;
  max-height: 450px;
}


.profile-box {
  border: 1px solid #ccc;
  background: radial-gradient(circle at 24.1% 68.8%, rgb(50, 50, 50) 0%, rgb(0, 0, 0) 99.4%);
  padding: 10px;
  text-align: center;
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  flex-direction: column;
}

.profile-box:hover .prev,
.profile-box:hover .next {
  display: block;
}

.profile-box h2 {
  margin-bottom: 10px;
}
